import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { rhythm, scale } from '../utils/typography'
import logo from '../images/png/3Dmeet-logo-full.png'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const blogPath = `${__PATH_PREFIX__}/blog/`
    const legalPath = `${__PATH_PREFIX__}/legal/`
    let headerLinkPath = `/`
    if (location.pathname.includes(blogPath)) {
      headerLinkPath = `/blog/`
    } else if (location.pathname.includes(legalPath)) {
      headerLinkPath = `/legal/`
    }
    let header

    if (location.pathname === blogPath || location.pathname === legalPath) {
      header = (
        <h1
          style={{
            ...scale(1.15),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={headerLinkPath}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
            marginBottom: '-4.25rem',
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={headerLinkPath}
          >
            <img src={logo} style={{ height: '3em' }} alt="logo" />
          </Link>
        </h3>
      )
    }
    return (
      <Wrapper>
        <ToastContainer
          limit={1}
          position="bottom-left"
          autoClose={8000}
          hideProgressBar={true}
        />
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

export default Layout
